import styled from 'styled-components';  

const maxWidth = 740

export const Container = styled.div`  
    align-self: center;
    display: flex;
    flex-direction: column;
    width: 80%;
    margin-top: 30px;
    margin-bottom: 80px;
    @media (max-width: ${maxWidth}px) { 
      padding: 20px;
    } 
`;

export const Header = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Title = styled.label`
    font-size: 18px;
    color: #005C37;
    font-family: 'Soleil', sans-serif;
    font-weight: 700;
`
export const Subtitle = styled.label`
    font-weight: 700;
    font-size: 28px;
    color: #2C2C31;
    margin-top: 15px;
    font-family: 'Soleil', sans-serif;
`

export const Image = styled.img`
    width: 35px;
    height: 35px;
`

export const Text = styled.p`
    color: #747880; 
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    font-family: 'Inter', sans-serif;
`


export const ContainerCards = styled.div`
    flex-direction: row;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    @media (max-width: ${maxWidth}px) { 
      flex-wrap: nowrap;
      flex-direction: column; 
    }
`
export const ContentCard = styled.div`
    /* margin-top: 40px; */
    /* border: 1px solid red; */
    margin: 10px;

`
export const Card = styled.div`
   flex-direction: row;
   display: flex;
   margin-bottom: 20px; 
   width: 45%;
   justify-content: space-between;
   margin-top: 20px;
   margin-bottom: 20px;
   @media (max-width: ${maxWidth}px) { 
        width: 100%;
        margin-bottom: 14px;
    }
`

export const CardTitle = styled.label`
    color:  #2C2C31;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: 'Inter', sans-serif;
`

export const SubContentCard = styled.label`
    /* margin: 30; */
    flex-direction: column;
    margin-left: 20px;
    display: flex;
    @media (max-width: ${maxWidth}px) { 
        margin: 10px;
    }
`

export const DoneContent = styled.div`
    border-radius: 8px;
    background: rgba(0, 210, 126, 0.10);
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 14px;
`
export const CardSubtitle = styled.label`
    color: #747880;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    font-family: 'Inter', sans-serif;
`

export const ContentImage = styled.div`
    background: rgba(215, 222, 225, 0.40);
    border-radius: 10px;
    width: 25%;
    height: 128px;
    position: absolute;
    /* display: flex; */
    right: 5%;
    /* top: 20px; */
    bottom: -220px;
    justify-content: center;
    align-items: center;
    display: flex;
    padding-left: 20px;
    @media (max-width: ${maxWidth}px) { 
        bottom: -132%;
        width: 50%;
    }
`

export const LeftContent = styled.div`
   width: 40%;
   @media (max-width: ${maxWidth}px) { 
     width: 100%;
    }
`

export const RightContent = styled.div`

   width: 60%; 
   @media (max-width: ${maxWidth}px) { 
     /* width: 100%; */
     display: none;
    }
`

 