import React from 'react';

import { Container, Title, ContentCard, Card, Header, TitleCard, DescriptionCard, ContentName, Image, Name, Profession} from './styles';

const reviews = [{
    picture: require('../../../assets/images/review1.png'),
    profession: 'Empreendedora',
    name: 'Vanessa Silva',
    title: 'Satifeita',
    description: 'Mais de 30% do nosso atendimento é automático, melhoramos nosso suporte e otimizamos o processo de vendas da nossa empresa.'
}, {
    picture: require('../../../assets/images/review2.png'),
    profession: 'Empreendedora',
    name: 'Larissa Vilanova',
    title: 'Sensacional',
    description: 'O TigerDesk tem ajudado muito em nosso atendimento, organizando e aumentando nossa capacidade de oferecer suporte ao cliente.'
}, {
    picture: require('../../../assets/images/review3.png'),
    profession: 'Empreendedor',
    name: 'Igor Maurício',
    title: 'Prático',
    description: 'Agora eu consigo criar soluções além do chatbot e acabei de vez com todas as dificuldades que tinha para atender todos os meus clientes. Filas de espera zeradas e agora meus clientes possuem o melhor atendimento.'
}];


const Reviews: React.FC = () => {
  return (
    <Container>
        <Title> Veja o que estão dizendo sobre nós </Title>
        <ContentCard>
            {
                reviews.map(el => (
                    <Card>
                        <Header>
                            <Image src={el.picture} alt="Pic"/>
                            <ContentName>
                                <Name> {el.name} </Name>
                                <Profession> {el.profession} </Profession>
                            </ContentName> 
                        </Header>
                        <TitleCard> {el.title} </TitleCard>
                        <DescriptionCard> {el.description } </DescriptionCard>
                    </Card>
                ))
            }
          
        </ContentCard>
    </Container>
  )
}

export default Reviews;