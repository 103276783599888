import React, { useEffect, useState } from 'react'; 

import { Container, PeriodPlan, Price, Subtitle, Text, ContentPlan, Title, Button, Group, Item, Label, ContainerPlans, Header, Cheap, RocketImage, ChatImage } from './styles';
import Check from '../../../assets/images/check.svg';
import Rocket from '../../../assets/images/rocket.svg';
import Chat from '../../../assets/images/chat.svg';

import api from '../../../http/api'; 
import currency from 'currency.js';

interface IPlan {
  id: number;
  title:string;
  description:string;
  benefits:string[],
  uuid:string;
  price:string;
  cycle:string; 
  link: string;
  planReference:string;
  active:boolean;
  createdAt:string;
  updatedAt:string;
}

interface Props {
  setContactVisible(v: boolean): void,
  setSelectedPlan(v: string): void,
}

const Plan = ({ setContactVisible, setSelectedPlan }: Props) => {
  const [plans, setPlans] = useState<IPlan[]>([]);
  const getPlans = async () => {
    try {
      const result = await api.get('/Plans');
      console.log('Result', result.data.result)
      setPlans(result.data.result);
    } catch(err) {
      console.log('Error', err)
    }
  }

  const period = (cycle: string) => {
    switch(cycle) {
      case 'MONTHLY':
        return 'mês'
      case 'YEARLY':
        return 'ano'
    }
  }

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <Container id='plans'>
      <Header>
        <Title>
          PREÇOS E PLANOS
        </Title>
        <Subtitle>
        Escolha o plano ideal para turbinar seus atendimentos e vendas
        </Subtitle>
        <Text> Otimize o atendimento ao cliente e aumente <br />
        suas vendas e conversões </Text>
      </Header>

    <div style={{position: 'relative'}}>
    <RocketImage src={Rocket} />

     <ContainerPlans> 
     {
        plans.map((el, i) => (
          <>
           
            <ContentPlan key={el.id} >
              {
                i + 1 === 2 && 
                <Cheap>
                  Mais recomendado
                </Cheap>
              }

              {
                i + 1 === 3 && 
                <ChatImage src={Chat}/>
              } 
             
              <Title style={{marginTop: 0}}> {el.title}</Title>
              <div style={{flexDirection: 'row', marginBottom: 32}}> 
                <Price> {currency(el.price).format({symbol: 'R$', separator: ',', decimal: '.', precision: 2})}</Price> <PeriodPlan>/{period(el.cycle)}</PeriodPlan>
              </div>
              <Group>
                {
                  el.benefits.map(item => {
                    return (
                      <Item> 
                        <div>
                          <img src={Check} alt="Check"/>
                        </div>
                        <div style={{justifyContent: 'left', display: 'flex', marginLeft: 10 }}>
                          <Label> {item} </Label> 
                        </div> 
                      </Item>
                    )
                  })
                }
              </Group>
              <Button
                onClick={() => {
                  setContactVisible(true);
                  setSelectedPlan(el.title);
                }}
              >
                Experimentar agora
              </Button>
            </ContentPlan>
          </>
        ))
      } 
     </ContainerPlans> 
     </div>
   
    </Container>
  )
}

export default Plan;