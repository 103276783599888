import React from 'react';
import { cardData } from './data';

import { Container, Icon, Card, Title, Description } from './styles';

const contentCards: React.FC = () => {
  return (
    <Container>
      {
        cardData.map(el => (
          <Card key={el.title}>
            <Icon src={el.icon} />
            <Title> {el.title} </Title>
            <Description> {el.description} </Description>
          </Card>
        ))
      }
    </Container>
  )
}

export default contentCards;