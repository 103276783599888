import SendMessageImg from '../../../assets/images/sendmessage.png'
import MoneyImage from '../../../assets/images/money.png'
import FilterImage from '../../../assets/images/filter.png';
import LightImage from '../../../assets/images/light.png'
import BotImage from '../../../assets/images/bot.svg'
import DepartmentImage from '../../../assets/images/departments.png'

type Benefit = {
    title: string;
    description: string;
    icon: string;
}

export const benefits: Benefit[] = [{
    title: 'Envio de mensagens em massa',
    description: 'Envie mensagens em massa para seus leads, o envio pode ser agendado ou instantâneo',
    icon: SendMessageImg,
}, {
    title: 'Economize no atendimento',
    description: ' Tenha vários atendentes conectados e atendendo ao mesmo número de WhatsApp',
    icon:  MoneyImage,
},{
    title: 'Crie funis de vendas automatizados no WhatsApp',
    description: 'Com TigerDesk é fácil criar funis com sequência de mensagens automáticas para seus leads',
    icon: FilterImage,
},{
    title: 'Gestão Intuitiva',
    description: 'Relatórios claros, monitoramento em tempo real e insights valiosos para o seu negócio.',
    icon: LightImage,
},{
    title: 'Automatize com Chatbots',
    description: 'Crie chatbots inteligentes com a nossa tecnologia e responda seus clientes 24h por dia, 7 dias por semana',
    icon: BotImage,
},{
    title: 'Organização por Departamento',
    description: 'Classifique, distribua e gerencie os atendimentos de maneira organizada, garantindo que cada cliente receba a atenção necessária.',
    icon: DepartmentImage
}]