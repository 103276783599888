import { Routes, Route, BrowserRouter } from 'react-router-dom';
import Dashboard from '../pages/dashboard/dashboard';
 
export default function RoutesComponent() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Dashboard /> } /> 
      </Routes>
    </BrowserRouter>
  );
}
