import styled from 'styled-components';
import background from '../../../assets/images/fundo.svg'

const maxWidth = 740

export const Container = styled.div`
    background-image: url(${background});
    background-size: cover; /* A imagem de fundo será dimensionada para cobrir toda a div */
    background-position: center;
    width: 100%;
`
export const HeaderContent = styled.header`
    flex-direction: row;
    display: flex;
    margin-left: 10%;
    margin-right: 10%;  
    /* border: 1px solid black; */
    padding: 10px;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    @media (max-width: ${maxWidth}px) { 
        margin-left: 20px;
        margin-right: 20px; 
    }
`; 

export const ContentHeader = styled.div`
    /* flex-direction: row;
    display: flex; */
    /* 
    height: 80%;
    background-size: 50%; */
    background-size: cover;
    background-repeat: no-repeat;
    height: 100%;
    width: 100%;
    /* margin-bottom: 50px; */
`;

export const ContentHeaderCenter = styled.div` 
    flex-direction: row;
    display: flex;
    @media (max-width: ${maxWidth}px) { 
        display: none;
    }

`; 


export const ContentOptionsHeader = styled.div`
    justify-content: center;
    align-items: center;
    display: flex;
`; 


export const HeaderItem = styled.a<{isHover?: boolean, color?: string, right?: number}>`
    color: ${props => props.color ? props.color : 'black'};
    margin-right: ${props => props.right ? props.right : 0}px;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    text-decoration: none;
    &:hover {
        /* background-color: rgba(0, 210, 126, 0.1);
        position: relative;
        padding: 6px;
        border-radius: 10px;
        color: ${props => props.isHover ? props.color: undefined }; */
    }
`;  

export const ContentHeaderItem = styled.div`
    padding: 10px 20px 10px 20px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-radius: 20px;
    margin: 0px 10px 0px 10px;
    &:hover {
        background-color: rgba(0, 210, 126, 0.1); 
    }
     
`;
 
export const ImageFund = styled.img`
    position: absolute ;
    width: 100%;
    height: 80%; 
`;

export const LogoImg = styled.img`
    width: 140px;
    height: 12%;
`;

export const ContainerPlan = styled.div`
  flex-direction: row; 
  display: flex;
  justify-content: space-between;
  flex-flow: row wrap;
`

export const Center = styled.div` 
   margin-left: 16%;
   margin-right: 16%; 
   margin-bottom: 100px; 
   padding: 20px;
   align-items: center;
   justify-items: center;
   display: flex;
   flex-direction: column;
   /* border: 1px solid black; */
   margin-top: 120px;
   @media (max-width: ${maxWidth}px) { 
        padding: 10px;
        margin-left: 10px;
        margin-right: 10px;
        margin-top: 30px;
    }
`

export const Text = styled.p`
   font-size: 60px;
   font-weight: 700;
   color: #2C2C31;
   text-align: center;
   font-family: 'Soleil', sans-serif;
   @media (max-width: ${maxWidth}px) { 
        font-size: 40px;
    }
`
export const TextMedium = styled.p`
   font-size: 20px;
   font-weight: 400;
   text-align: center;
   font-family: 'Inter', sans-serif;
   color: #747880;
   line-height: normal;
`

export const ContentPlan = styled.div`
    padding: 10px;
    border-radius: 10px; 
    width: 30%;
`

export const Button = styled.a`
    background-color: green;
    padding: 10px;
    border-radius: 20px;
    justify-content: center;
    color: black;
    cursor: pointer;
    /* align-self: center; */
`

export const StyledButton = styled.a`
    border: 1.5px solid black;
    padding: 10px 20px;
    border-radius: 50px;
    color: black;
    font-weight: 700;
    background-color: white;
    font-size: 14px;
    text-decoration: none;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    @media (max-width: ${maxWidth}px) { 
        display: none;
    }
    &:hover {
        background-color: #00D27E;
        border-color: #fff;
        color: #fff;
    }
`;

export const StartNow = styled.a`
    border: 1.4px solid black;
    border-radius: 50px;
    background-color: black;
    color: white;
    text-decoration: none;
    text-align: center;
    margin-top: 40px;
    width: 224px;
    height: 52px;
    font-size: 16px;
    font-weight: 700;
    align-self: center;
    font-family: 'Inter', sans-serif;
    cursor: pointer;
    &:hover {
        background-color: #00D27E;
        border-color: #fff;
    }
`; 