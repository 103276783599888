"use client"

export const i18n: Record<string, Record<string, string>> = {
    'pt-BR': {
        banner: 'A Zapfy está com planos diferenciais disponível para clientes que possuem interesse em grandes volumes (acima de 50 instâncias).',
        bannerLink: 'Ver sobre',
        pannel: 'Painel',
        freeTest: 'Teste Grátis',
        about: 'Sobre',
        services: 'Serviços',
        integrations: 'Integrações',
        plans: 'Planos',
        documentation: 'Documentação',
        headerHero: 'Planos especiais para clientes com grandes demandas, vamos bater um papo?',
        heroText: '👋 Integre sua empresa com o WhatsApp',
        heroDescription: 'Assinatura única, sem cobrança extra por mensagem, faça a integração com o WhatsApp via API REST de maneira fácil, rápida, segura e prática.',
        heroDocButton: 'Ver Documentação',
        heroFooter1: '+ de 50 clientes confiam em nossa solução',
        heroFooter2: 'Está esperando o que? Faça parte dessa lista e torne-se um parceiro da Zapfy!',
        aboutHeader: 'O que é a Zapfy?',
        about1: 'A Zapfy é uma API muito intuitiva que te permite criar uma integração entre sua empresa e o WhatsApp de forma muito mais ágil.',
        about2: 'Com inúmeras possibilidades de integração como:',
        about3: `Construção de bot’s de atendimento, Agendamento de consultas, Notificações por Whatsapp e outras centenas de possibilidades`,
        about4: `Você também pode monitorar tudo por webhook. Agora ficou fácil né?`,
        aboutButton: 'Se interessou? Teste grátis agora mesmo',
        featureTitle: 'Serviços disponíveis para qualquer plano',
        featureDesc: 'Nossa API é de fácil de integração, possui uma documentação intuitiva e suporte 24/7.',
        feature1Title: 'Envio de Mensagens',
        feature1Desc: 'Envio de mensagens, seja ela texto, imagem, áudio, localização, botões ou listas, disponibilizamos todos os tipos de envio de mensagem com suporte à envios em massa e processamento super rápido!',
        feature2Title: 'Gerenciamento de Grupos',
        feature2Desc: 'Nossa API permite que você gerencie grupos no WhatsApp, oferecemos todas as funcionalidades disponíveis para grupos do WhatsApp.',
        feature3Title: 'Alta Disponibilidade',
        feature3Desc: 'Aqui você não vai ter problema com bloqueios, celulares desconectando e mensagens não enviadas. Garantimos alta disponibilidade de nosso serviço para todos os nossos clientes.',
        feature4Title: 'Suporte Especializado',
        feature4Desc: 'Você terá total apoio de um suporte completamente especializado, estaremos disponíveis para te ajudar em todas as etapas da integração.',
        integrationTitle: 'Diversas integrações disponíveis',
        integrationDescription: ' Usa alguma ferramenta ou tecnologia que a gente não tem integração?',
        integrationDescription2: 'Mande nos uma mensagem que resolvemos agora mesmo 😉',
        pricingTitle: 'Planos Disponíveis',
        pricingDesc: 'Nossos planos são cobrados de acordo com a quantidade de instâncias (conexões no WhatsApp) adquiridas.',
        planStart: 'Plano Starter',
        planAdvanced: 'Plano Advanced',
        planUltimate: 'Plano Ultimate',
        planUltimateDesc: 'Disponível a partir de 100 instâncias, valor cobrado por instância.',
        planAdvancedDesc: 'Disponível a partir de 50 instâncias, valor cobrado por instância.',
        start1: 'Para pequenas empresas ou projetos pequenos, valor cobrado por instância.',
        startPrice: 'R$ 89.00',
        advancedPrice: 'R$ 60',
        monthly: 'mensal',
        startContact: 'Entrar em contato',
        pricingFeature1: 'Sem limites de envio de mensagens',
        pricingFeature2: 'Multidevice',
        pricingFeature3: 'Arquivos até 3mb',
        pricingFeature4: 'Fila de mensagens',
        pricingFeature5: 'Envio de arquivos',
        pricingFeature6: 'Suporte horário comercial',
        pricingFeature7: 'Auxílio disponível em toda a implementação',
        pricingFeature8: 'Infraestrutura exclusiva',
        pricingFeature9: 'Grupo Exclusivo e Monitoramento exclusivo',
        negotiable: 'Negocíavel',
        footerContent: 'Conteúdo',
        contactWhats: 'Contato (WhatsApp)',
        copy: 'Todos os direitos reservados.',
        formName: 'Seu nome completo',
        formEmail: 'Seu email',
        formPhone: 'Seu telefone',
        formZap: 'Se preferir, entre em contato via WhatsApp',
        formSend: 'Enviar',
        objective: 'Qual o seu objetivo?',
        loading: 'Carregando...'
    },
    'en-US': {
        banner: 'Zapfy has special plans available for customers interested in large volumes (above 50 instances).',
        bannerLink: 'See more',
        pannel: 'Dashboard',
        freeTest: 'Free Trial',
        about: 'About',
        services: 'Services',
        integrations: 'Integrations',
        plans: 'Plans',
        documentation: 'Documentation',
        headerHero: 'Special plans for customers with high demands. Shall we have a chat?',
        heroText: '👋 Integrate your business with WhatsApp',
        heroDescription: 'Single subscription, no extra charge per message. Easily, quickly, securely, and conveniently integrate with WhatsApp via REST API.',
        heroDocButton: 'View Documentation',
        heroFooter1: 'More than 50 clients trust our solution',
        heroFooter2: "What are you waiting for? Join this list and become a Zapfy partner!",
        aboutHeader: 'What is Zapfy?',
        about1: 'Zapfy is a very intuitive API that allows you to create integration between your company and WhatsApp in a much faster way.',
        about2: 'With numerous integration possibilities such as:',
        about3: 'Building customer service bots, Appointment scheduling, WhatsApp notifications, and hundreds of other possibilities.',
        about4: 'You can also monitor everything through webhooks. Now it has become easy, right?',
        aboutButton: 'Interested? Test it for free right now',
        featureTitle: 'Services available for any plan',
        featureDesc: 'Our API is easy to integrate, has intuitive documentation, and 24/7 support.',
        feature1Title: 'Message Sending',
        feature1Desc: 'Send messages, whether it is text, image, audio, location, buttons, or lists. We provide all types of message sending with support for bulk messaging and super-fast processing!',
        feature2Title: 'Group Management',
        feature2Desc: 'Our API allows you to manage WhatsApp groups, offering all the functionalities available for WhatsApp groups.',
        feature3Title: 'High Availability',
        feature3Desc: "Here you won't have any problems with blocks, disconnected phones, or undelivered messages. We guarantee high availability of our service for all our customers.",
        feature4Title: 'Specialized Support',
        feature4Desc: 'You will have full support from a completely specialized team. We will be available to assist you in all stages of integration.',
        integrationTitle: 'Various integrations available',
        integrationDescription: 'Do you use a tool or technology that we do not have integration with?',
        integrationDescription2: 'Send us a message and we will resolve it right away 😉',
        pricingTitle: 'Available Plans',
        pricingDesc: 'Our plans are charged according to the quantity of instances (connections to WhatsApp) acquired.',
        planStart: 'Starter Plan',
        planAdvanced: 'Advanced Plan',
        planUltimate: 'Ultimate Plan',
        planUltimateDesc: 'Available from 100 instances, price charged per instance.',
        planAdvancedDesc: 'Available from 50 instances, price charged per instance.',
        start1: 'For small businesses or small projects, price charged per instance.',
        startPrice: 'R$ 89.00',
        advancedPrice: 'R$ 60',
        monthly: 'monthly',
        startContact: 'Contact Us',
        pricingFeature1: 'No limits on message sending',
        pricingFeature2: 'Multi-device',
        pricingFeature3: 'Files up to 3mb',
        pricingFeature4: 'Message queue',
        pricingFeature5: 'File sending',
        pricingFeature6: 'Business hours support',
        pricingFeature7: 'Assistance available throughout implementation',
        pricingFeature8: 'Exclusive infrastructure',
        pricingFeature9: 'Exclusive Group and Monitoring',
        negotiable: 'Negotiable',
        footerContent: 'Content',
        contactWhats: 'Contact (WhatsApp)',
        copy: 'All rights reserved.',
        formName: 'Your full name',
        formEmail: 'Your email',
        formPhone: 'Your phone number',
        formZap: 'If you prefer, contact us via WhatsApp',
        formSend: 'Send',
        objective: 'What is your goal?',
        loading: 'Loading...'
    }
}

export const t = (key: string, lang: string) => {
    if (lang === 'pt-BR') {
        return i18n[lang][key];
    }

    return i18n['en-US'][key];
}