import React, { useState } from 'react';
 
import { Container } from './styles';
import Plan from './plan';
import Footer from './footer';
import Header from './header';
import HowWorks from './howWorks';
import Benefits from './benefits';
import ContentCards from './contentCards';

import Reviews from './reviews';
import { ContactForm } from '../../components/ContactForm/contact-form';

const Dashboard: React.FC = () => {
   const [contactVisible, setContactVisible] = useState(false);
   const [selectedPlan, setSelectedPlan] = useState('');

   return (
      <Container>
         <ContactForm visible={contactVisible} selectedPlan={selectedPlan} onClose={() => setContactVisible(false)} />
         <Header setContactVisible={setContactVisible} />
         <ContentCards />
         <Benefits />
         <HowWorks />
         <Reviews />
         <Plan setContactVisible={setContactVisible} setSelectedPlan={setSelectedPlan} />
         <Footer />
      </Container>
   )
}

export default Dashboard;