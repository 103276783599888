import { useEffect, useState } from "react"
import Modal from 'react-modal';
import { Button, Input, Select, Label, Container, ContainerForm } from './styles';
import { useMediaQuery } from 'react-responsive';
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

import { t } from "../../i18n/i18n"
import Spinner from "../Spinner";

interface Props {
  visible: boolean,
  selectedPlan: string | undefined,
  onClose(): void,
}

interface Contact {
  name: string,
  phone: string,
  email: string,
  objective: string,
}

export const ContactForm: React.FC<Props> = ({ visible, selectedPlan, onClose }) => {
  const [contact, setContact] = useState<Contact>({
    objective: 'Quero desenvolver um sistema integrado com o WhatsApp',
  } as Contact);
  const isMobile = useMediaQuery({ query: `(max-width: 760px)` });
  const [loadingRegisterLead, setLoadingRegisterLead] = useState(false);

  const onChangeContact = (k: keyof Contact, v: string) => setContact(c => ({ ...c, [k]: v }))

  const onSubmit = async () => {
    setLoadingRegisterLead(true);

    if (!contact?.email || !contact?.phone) {
      alert('Preencha o seu email e telefone para continuar.');
      setLoadingRegisterLead(false);
      return
    }

    try {
      await fetch('https://registerlead.tigerdesk.com.br', {
        method: 'POST',
        body: JSON.stringify({ ...contact, source: 'form-site', selectedPlan }),
        headers: {
          'Content-Type': 'application/json'
        }
      });
    } catch (e) {
      console.log(e)
    }

    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any)?.dataLayer.push({
      event: 'generate_lead',
      user: {
        data: {
          ...contact,
        },
      },
      product: {
        plan: selectedPlan,
      },
    });

    toast.success("Dados enviados com sucesso! Entraremos em contato em breve.");

    setLoadingRegisterLead(false)
    onClose();
  }

  const normalizePhoneNumber = (value: String | undefined) => {
    if (!value) return ''
      
    return value.replace(/[\D]/g, '')
      .replace(/(\d{2})(\d)/, '($1) $2')
      .replace(/(\d{5})(\d)/, '$1-$2')
      .replace(/(-\d{4})(\d+?)/, '$1')
  }

  useEffect(() => {
      onChangeContact("phone", normalizePhoneNumber(contact.phone))
    },[contact.phone])

  return (
    <>
      <ToastContainer />
      <Modal 
        isOpen={visible}
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '50%',
            left: '50%',
            right: '-50%',
            maxWidth: isMobile ? '70%' : '40vw',
            margin: isMobile ? 20 : 0,
            bottom: 'auto',
            borderRadius: '8px',
            transform: 'translate(-50%, -50%)',
            width: '100%',
            padding: '40px',
            border: 'none',
            background: '#fff',
          },
        }}
      >
        <Button onClick={onClose}>X</Button>
        <Container>
          <h1>{t('startContact', window.navigator.language)}</h1>

          <ContainerForm>
            <Label>{t('formName', window.navigator.language)}</Label>
            <Input placeholder={t('formName', window.navigator.language)} value={contact.name} onChange={e => onChangeContact('name', e.target.value)} />
          </ContainerForm>
          
          <ContainerForm>
            <Label>{t('formPhone', window.navigator.language)}</Label>
            <Input placeholder={t('formPhone', window.navigator.language)} value={contact.phone} onChange={e => onChangeContact('phone', e.target.value)} />
          </ContainerForm>
          <ContainerForm>
            <Label>{t('formEmail', window.navigator.language)}</Label>
            <Input placeholder={t('formEmail', window.navigator.language)} value={contact.email} onChange={e => onChangeContact('email', e.target.value)} />
          </ContainerForm>

          <ContainerForm>
            <Label>{t('objective', window.navigator.language)}</Label>
            <Select value={contact.objective} onChange={e => onChangeContact('objective', e.target.value)}>
              <option value="Tenho um e-commerce e quero disparar notificações">Tenho um e-commerce e quero disparar notificações</option>
              <option value="Quero criar um chat bot">Quero criar um chat bot</option>
              <option value="Quero criar funis de vendas">Quero criar funis de vendas</option>
              <option value={'other'}>Tenho outro objetivo</option>
            </Select>
          </ContainerForm>
          {loadingRegisterLead ? <Spinner /> : (
            <Button onClick={() => onSubmit()}>{t('formSend', window.navigator.language)}</Button>
          )}
        </Container>
      </Modal>
    </>
  )
}