import styled from 'styled-components';  

const maxWidth = 740

export const Container = styled.footer` 
    margin-top: 20px;
    background-color: #2C2C31;
    padding: 32px 100px;
    @media (max-width: ${maxWidth}px) { 
      padding: 20px;
    }
`; 

export const Logo = styled.img` 
  width: 200px;
  height: 50px;
  /* margin-top: 100px; */
`; 

export const Title = styled.label` 
   color: #FFF;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
`; 

export const Divider = styled.div` 
   width: 100%;
   height: 0.4px;
   background-color: white;
   margin-top: 48px;
`; 

export const Text = styled.label` 
   color: #FFF;
    /* font-family: Inter; */
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
`;

export const ContentText = styled.div`
    width: 40%;
    justify-content: center;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    margin: 10px;
    justify-content: flex-start;
    @media (max-width: ${maxWidth}px) { 
      width: 100%;
      margin-left: 0px;
   } 
`;

export const ContentTextCenter = styled.div`
    width: 40%;
    justify-content: center;
    /* align-items: center; */
    display: flex;
    flex-direction: column;
    margin: 10px;
    margin-left: 140px;
    @media (max-width: ${maxWidth}px) { 
      width: 100%;
      margin-left: 0px;
   } 
`; 

export const Content = styled.div` 
   display: flex;
   flex-direction: row;
   justify-content: space-between;
   @media (max-width: ${maxWidth}px) { 
      flex-direction: column;
   }  
`; 

export const ContentBottom = styled.div` 
   margin-top: 10px;
   display: flex;
   flex-direction: row;
   justify-content: space-between;
`; 