import React from 'react';
import { Container, HeaderContent, HeaderItem, ContentHeaderCenter, ContentHeader, LogoImg, ContentOptionsHeader, StyledButton, Center, Text, TextMedium, StartNow, ContentHeaderItem } from './styles';
import Logo from '../../../assets/images/logo.png'
import LoginSvg from '../../../assets/images/login.svg';

interface Props {
    setContactVisible(v: boolean): void,
}

const Header: React.FC<Props> = ({ setContactVisible }) => {
  return (
      <Container> 
            <ContentHeader>
                <HeaderContent>
                    <LogoImg src={Logo} alt="Logo" />
                    <ContentHeaderCenter>
                        <ContentHeaderItem>
                        <HeaderItem isHover={true} color={'#2C2C31'} href="#tools"> Ferramenta</HeaderItem>
                            </ContentHeaderItem>
                        <ContentHeaderItem>
                            <HeaderItem isHover={true} color={'#2C2C31'} href="#plans"> Planos </HeaderItem>
                        </ContentHeaderItem>
                    </ContentHeaderCenter> 
                    <ContentOptionsHeader>
                        <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', display: 'flex'}}> 
                        <img src={LoginSvg} alt="Login" style={{ marginRight: 10 }}/>
                        <HeaderItem right={40} onClick={() => window.open('https://app.tigerdesk.me/', '_self')}>  Entrar </HeaderItem>
                        <StyledButton onClick={() => setContactVisible(true)}> <label> Quero Conhecer </label> </StyledButton>
                        </div>
                    </ContentOptionsHeader> 
                </HeaderContent>
                <Center>
                    <Text>
                    Impulsione suas vendas através do Whatsapp
                    </Text>
                    <TextMedium>
                    Otimize a comunicação, automatize respostas e potencialize seus lucros.
                    </TextMedium>
                    <StartNow href="#plans"> <p> COMEÇAR  AGORA </p> </StartNow>
                </Center>
            </ContentHeader>
        </Container>
  )
}

export default Header;