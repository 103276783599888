import SendSvg from '../../../assets/images/send.svg';
import GameSvg from '../../../assets/images/game.svg';
import MessageSvg from '../../../assets/images/message.svg';

interface Card {
    icon: string;
    title: string;
    description: string;
}


export const cardData: Card[] = [{
    icon: SendSvg,
    title: 'Mensagens em escala',
    description: 'Mantenha contato direto com seus leads, enviando mensagens em massa, seja de forma programada ou instantânea'
}, {
    icon: MessageSvg,
    title: 'Atendimento 24/7',
    description: 'Nossos chatbots estão prontos para atender seus clientes a qualquer hora do dia, todos os dias da semana.'
}, {
    icon: GameSvg,
    title: 'Controle total',
    description: 'Monitore e gerencie sua equipe, garantindo um atendimento de qualidade e otimizando seus processos.'
}]