import React from 'react';

import { Container, Title, Subtitle, Text, Card, CardTitle, CardSubtitle, SubContentCard, DoneContent, ContainerCards, Header, Image} from './styles';
import { benefits } from './data';

const Benefits: React.FC = () => {
  return (
    <Container>
        <Header>
            <Title> Comece seu teste grátis agora! </Title>
            <Subtitle> Mais do que uma ferramenta, uma revolução nas suas vendas. </Subtitle>
            <Text>
            Entenda por que o TigerDesk é a escolha certa para o seu negócio:
            </Text>
        </Header>
        <ContainerCards> 
            {
                benefits.map(benefit => 
                    <Card>
                        <DoneContent >
                            <Image src={benefit.icon} alt="Done" />
                        </DoneContent>
                        <SubContentCard>
                            <CardTitle>
                                {benefit.title}
                            </CardTitle>
                            <CardSubtitle>
                                {benefit.description}
                            </CardSubtitle>
                        </SubContentCard> 
                    </Card>
            )}
           
        </ContainerCards>
    </Container>
  )
}

export default Benefits;