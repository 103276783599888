import React from 'react';

import { Container, Title, Divider, Text, Logo, Content, ContentText, ContentBottom, ContentTextCenter} from './styles';
import LogoBranco from '../../../assets/images/logo-branca.png';
import Instagram from '../../../assets/images/instagram.svg';

const Footer: React.FC = () => {
  return (
      <Container>
          <Logo src={LogoBranco}  alt="Logo"/>
          <Content>
              <ContentText>
                <Title>
                Somos especialistas em automação através do WhatsApp.
                </Title> <br />
                <Text style={{textAlign: 'start'}}>
                Você pode contar com a nossa equipe para desenvolver automações que efetivamente resultam em agendamentos, visitas e vendas para o seu empreendimento.
                </Text>
              </ContentText>
              <ContentTextCenter>
                <Title>
                Fale conosco
                </Title> <br />
                <a style={{ color: 'white', cursor: 'pointer', fontSize: 12, fontWeight: 400 }} href='mailto:contato@tigerdesk.com.br'>
                contato@tigerdesk.com.br
                </a>
              </ContentTextCenter>
              <ContentText>
                <Title>
                Siga nossas redes sociais
                </Title> <br />
                <a href="https://www.instagram.com/tigerdesk.me/">
                  <img src={Instagram} alt="Instagram" style={{ height: 20, width: 20, cursor: 'pointer'}}/> 
                </a>
                <Text>
                </Text>
              </ContentText>
          </Content>
          <Divider />
          <ContentBottom>
             <Text> {new Date().getFullYear()} Tiger Desk • Todos os direitos reservados </Text>
             <div style={{flexDirection: 'row', display: 'flex'}}>
                <Text style={{marginRight: 14 }}> Políticas de Privacidade </Text>
                <Text> Termos de Uso </Text>
             </div>
          </ContentBottom>
      </Container>
  )
}

export default Footer;