import styled, { keyframes } from 'styled-components'; 
import background from '../../../assets/images/fundoblur.svg'

const pulseAnimation = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.5;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`;

export const Container = styled.div`
    background-image: url(${background});
    background-size: cover; /* A imagem de fundo será dimensionada para cobrir toda a div */
    background-position: center;
    margin-bottom: 40px;
    @media (max-width: 700px) { 
        padding: 10px;
    }
`; 

export const Title = styled.label`
    font-size: 18px;
    color: #005C37;
    margin-top: 80px;
`

export const Subtitle = styled.label`
    font-size: 28px;
    font-weight: 700;
    line-height: 30px;
    margin-top: 42px;
    margin-bottom: 28px;
    font-family: 'Inter', sans-serif;
    @media (max-width: 700px) { 
        text-align: center;
    }
`

export const Text = styled.label`
    color: #747880;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    margin-bottom: 100px;
`

export const ContainerPlans = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    @media (max-width: 700px) { 
        flex-direction: column; 
    }
`

export const ContentPlan = styled.div`
    padding: 24px;
    border-radius: 20px;
    background-color: #fff;
    box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.10);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 24%;
    margin: 14px;
    @media (max-width: 700px) { 
        width: 80%;
        order: 2;
    }
    position: relative;
    /* border: 1px solid black; */
`

export const RocketImage = styled.img`
    position: absolute;
    left: 10%; 
    /* bottom: -140%; */
    top: -100px;
    @media (max-width: 700px) {  
        /* left: 50px; */
        align-self: center;
        display: none;
    }
    /* top: 10px;
    justify-content: center;  */
`

export const ChatImage = styled.img`
    position: absolute;
    right: -70px;
    top: 20px;
    display: flex;
`

export const Price = styled.label`
    font-size: 40px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: #2C2C31;
`

export const PeriodPlan = styled.label`
    color:  #747880; 
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 0.16px;
`

export const Button = styled.a`
    border-radius: 50px;
    background-color: #00D27E;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    color: white; 
    gap: 10px;
    align-self: center;
    cursor: pointer;
`
export const Label = styled.label`
    font-weight: 400;
    font-size: 16px;
    color: #576477; 
    line-height: 20px;
    /* align-self: flex-end;
    display: flex;
    flex-direction: row; */
` 

export const Group = styled.div`
    margin-bottom: 32px;

    /* overflow-x: scroll; */
    align-items: center;
    justify-content: center;
`

export const Item = styled.div`
    flex-direction: row;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
`

export const Header = styled.div`
    display: flex;
    flex-direction: column;
    margin-bottom: 50px;
    align-items: center;
`
export const Cheap = styled.div`
    background-color: #2C2C31;
    border-radius: 30px;
    color: #fff;
    padding: 10px 20px;
    font-weight: 700;
    font-size: 16px;
    position: absolute;
    right: -26px;
    top: -16px;
    animation: ${pulseAnimation} 2s infinite;

    /* right: 200; */
`