import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column; 
`;

const balanceAnimation = keyframes` 
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(10px); /* Ajuste o valor conforme necessário */
    }
`
export const WhatsAppButton = styled.a`
    position: fixed;
    bottom: 20px;
    right: 30px;
    background-color: #00D27E;
    padding: 12px;
    border-radius: 50px;
    justify-content: center;
    cursor: pointer;
    animation: ${balanceAnimation} 0.4s linear infinite alternate;
`


export const WhatsappImage = styled.img`
    width: 40px;
    height: 40px;
    fill: #fff;
    stroke: #fff;
    color: #fff;
`;