import styled from 'styled-components';  

const maxWidth = 740

export const Container = styled.footer` 
    justify-self: center;
    align-self: center;
    display: flex;
    width: 80%;
    flex-direction: row;
    margin-bottom: 120px;
    margin-top: 80px;
    @media (max-width: ${maxWidth}px) {
      flex-direction: column;
      width: 90%;
    }
`; 

export const Card = styled.div`
    padding: 20px;
    background-color: #fff;
    border: 1px solid var(--black-opacity-10-p, rgba(44, 44, 49, 0.10));
    border-radius: 10px;
    padding: 24px;
    margin: 10px;
`
export const Title = styled.p`
    font-family: 'Inter', sans-serif;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
`

export const Icon = styled.img`

`

export const Description = styled.p`
    color: var(--black-main, #2C2C31);
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;

`