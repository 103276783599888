import styled from 'styled-components'; 

const maxWidth = 740;

export const Container = styled.div` 
    padding: 40px 150px 70px 160px;
    background-color: white;
    @media (max-width: ${maxWidth}px) { 
        padding: 10px;
    }
`; 

export const Title = styled.p`
    font-weight: 700;
    font-size: 28px;
    color: #2C2C31;
    margin-top: 15px;
    font-family: 'Soleil', sans-serif;
    text-align: center;
    @media (max-width: ${maxWidth}px) { 
        text-align: center;
        margin-left: 20px;

        margin-right: 20px;
        /* margin-left: 10px; */
    }
`

export const ContentCard = styled.div`
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 48px;
    @media (max-width:  ${maxWidth}px) { 
        flex-direction: column;
    }
`
export const Card = styled.div`
    padding: 32px;
    border-radius: 20px;
    background: #FFF;
    width: 30%;
    margin: 10px;
    box-shadow: 0px 2px 50px 0px rgba(0, 0, 0, 0.07), 0px 3px 7px 0px rgba(0, 0, 0, 0.02);
    @media (max-width:  ${maxWidth}px) { 
        width: 80%;
    }
`

export const Header = styled.div`
    flex-direction: row;
    display: flex;
    align-items: center;
`

export const ContentName = styled.div`
    margin-left: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
`
export const Name = styled.label`
    color:#2C2C31;
    font-size: 16px; 
    font-weight: 700;
`

export const Image = styled.img`
    width: 70px;
    height: 60px;
    border-radius: 100%;
    /* background-color: red; */
`

export const Profession = styled.label`
    color: #747880;
    font-size: 14px;
    font-weight: 400;
    /* bottom: 40px; */
    /* line-height: normal; */
`

export const TitleCard = styled.p`
   color: #2C2C31;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
`


export const DescriptionCard = styled.label`
    color:  #747880;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
`