import React from 'react';

import { Container, ContentHeader, LeftContent, RightContent, Subtitle, Title, Image, ContentCard, Card, CardTitle, CardSubtitle, DoneContent, Content, SubContentCard, ContentImage, LabelRight, Number } from './styles';
import Woman from '../../../assets/images/manphone.svg'

const HowWorks: React.FC = () => {
  return (
    <Container id="tools">
        <ContentHeader>
          <Title> Iniciando sua jornada: </Title>
          <Subtitle>Veja como você pode acelerar as vendas e <br />otimizar o atendimento da sua empresa em <label style={{textDecoration: 'underline #00D27E 5px'}}> poucos passos </label></Subtitle>
        </ContentHeader>

      <Content> 
      <LeftContent> 
        <ContentCard>
          <Card>
            <DoneContent>
              <Number> 01. </Number>
            </DoneContent>
            <SubContentCard>
              <CardTitle>ESelecione o Plano Perfeito</CardTitle>
              <CardSubtitle>
                Escolha o plano que melhor atende às demandas da sua empresa.
              </CardSubtitle>
            </SubContentCard> 
          </Card>
          <Card>
            <DoneContent>
            <Number> 02. </Number>
            </DoneContent>
            <SubContentCard>
              <CardTitle>
              Registre seus Departamentos, Atendentes e Números
              </CardTitle>
              <CardSubtitle>
              Uma vez com o plano em mãos, receba seu acesso por e-mail. Ao entrar no sistema, cadastre seus departamentos, atendentes e números de WhatsApp              </CardSubtitle>
            </SubContentCard> 
          </Card>
          <Card>
            <DoneContent>
            <Number> 03. </Number>
            </DoneContent>
            <SubContentCard>
              <CardTitle>
              Personalize seu Assistente Virtual
              </CardTitle>
              <CardSubtitle>
              Utilize nosso guia prático e configure seu robô para um atendimento otimizado.
              </CardSubtitle>
            </SubContentCard> 
          </Card>
          <Card>
            <DoneContent >
            <Number> 04. </Number>
            </DoneContent>
            <SubContentCard>
              <CardTitle>
              Hora de Atuar!
              </CardTitle>
              <CardSubtitle>
              A partir de agora, todos os seus clientes serão atendidos por um único número, categorizado por departamento.
              </CardSubtitle>
            </SubContentCard>  
          </Card> 
        </ContentCard>
      </LeftContent>
      <RightContent>
          <div style={{position: 'relative'}}>
            <ContentImage>
              <LabelRight> Otimize o atendimento e aumente as vendas e conversões </LabelRight>
            </ContentImage>
          <Image src={Woman}/>
          </div>
      </RightContent>
      </Content>
    
    </Container>
  )
}

export default HowWorks;