import styled from 'styled-components'; 

export const Button = styled.a`
    border-radius: 50px;
    background-color: #00D27E;
    padding: 10px 15px;
    justify-content: center;
    align-items: center;
    color: white; 
    gap: 10px;
    align-self: center;
    cursor: pointer;
    margin-top: 10px;
`
export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

export const Input = styled.input`
    border-radius: 50px;
    background-color: #fff;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    color: #000; 
    gap: 10px;
    align-self: center;
    cursor: pointer;
    border: 1px solid #000;
    width: 90%;
    // torne esse input responsivo
    @media (max-width: 768px) {
        width: 100%;
    }
`

export const Select = styled.select`
    border-radius: 50px;
    background-color: #fff;
    padding: 10px 32px;
    justify-content: center;
    align-items: center;
    color: #000; 
    gap: 10px;
    align-self: center;
    cursor: pointer;
    border: 1px solid #000;
    width: 100%;
`

export const Label = styled.label`
    color: #000;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
`

export const ContainerForm = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    margin-bottom: 20px;
`
