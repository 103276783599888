import styled from 'styled-components';  

const maxWidth = 740

export const Container = styled.div` 
    padding: 24px 30px 70px 160px; 
    flex-direction: column;
    display: flex;
    @media (max-width: ${maxWidth}px) { 
        flex-direction: column;
        padding: 20px; 
    }
`; 

export const Content = styled.div` 
    /* padding: 24px 30px 70px 160px;  */
    flex-direction: row;
    display: flex;
    @media (max-width: ${maxWidth}px) { 
        flex-direction: column;
        padding: 20px; 
    }
`;

export const Title = styled.label`
    font-size: 18px;
    color: #005C37;
    font-family: 'Inter', sans-serif;
    font-weight: 700;
    text-align: center;
`
export const ContentHeader = styled.div`
    flex-direction: column;
    display: flex;
    justify-content: center;
    justify-self: center; 
    @media (max-width: ${maxWidth}px) { 
        justify-content: center;
        align-items: center;
    }
`

export const Subtitle = styled.label`
    font-weight: 700;
    font-size: 28px;
    color: #2C2C31;
    margin-top: 15px;
    font-family: 'Inter', sans-serif;
    text-align: center;
`

export const LeftContent = styled.div`
   width: 40%;
   @media (max-width: ${maxWidth}px) { 
     width: 100%;
    }
`

export const RightContent = styled.div` 
   width: 70%; 
   /* border: 1px solid black; */
   @media (max-width: ${maxWidth}px) { 
     /* width: 100%; */
     display: none;
    }
    /* position: relative; */
`
export const Image = styled.img`
    width: 85%;
    height: 604px;
    margin-top: -50px;
    margin-left: 50px;
    @media (max-width: ${maxWidth}px) { 
        width: 100%;
        margin-top: 10px;
        margin-left: 0px;
        margin-right: 10px;
        height: 340px;
    }
`
export const ContentCard = styled.div`
    margin-top: 40px;
`
export const Card = styled.div`
   flex-direction: row;
   display: flex;
   margin-bottom: 40px;
`

export const CardTitle = styled.label`
    color:  #2C2C31;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-family: 'Inter', sans-serif;
`

export const SubContentCard = styled.label`
    margin: 30;
    flex-direction: column;
    display: flex;
    margin-left: 20px;
    @media (max-width: ${maxWidth}px) { 
        margin: 10px;
    }
`


export const DoneContent = styled.div`
    border-radius: 60px;
    background: #F5F6F6;
    justify-content: center;
    align-items: center;
    align-self: center;
    display: flex;
    /* gap: 10px; */
    padding: 15px;
`
export const CardSubtitle = styled.label`
    color: #747880;
    font-size: 14px;
    margin-top: 5px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    font-family: 'Inter', sans-serif;
`

export const Number = styled.label`
    color: #005C37;
    font-size: 20px;
    margin-top: 5px; 
    font-weight: 300;
    font-family: 'Inter', sans-serif;
`

export const ContentImage = styled.div`
    background: #FFFFFF;
    border-radius: 40px;
    width: 270px;
    height: 60px;
    padding: 10px, 16px, 10px, 16px;
    position: absolute;
    left: 5%; 
    top: 40%;
    justify-content: center;
    align-items: center;
    display: flex;
    @media (max-width: ${maxWidth}px) { 
        /* bottom: -132%; */
        margin-top: 35%;
        width: 50%;
    }
    @media (max-width: ${maxWidth + 300}px) { 
        bottom: -132%;
        margin-top: 0px;
        width: 42%;
    }
`

export const LabelRight = styled.label`
    color: #747880;
    /* font-family: Inter; */
    font-size: 14px;
    font-style: normal;
    font-weight: 500; 
    line-height: 20px;
    text-align: center;
    font-family: 'Inter', sans-serif;

`